.error-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #222222;
}

.error-page__splash {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  margin: 20px;
}

.error-page__notification-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 800px;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 24px 110px;
}

.error-page__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.error-page__code {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.error-page__code-text {
  font-size: 160px;
  margin: -50px;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.error-page__description {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-page__description-text {
  color: #555555;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.error-page__message-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e8e8e8;
  border-top: none;
  border-left: none;
  border-right: none;
}

.error-page__message-text {
  color: #888888;
  font-size: 18px;
}

.error-page__buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.error-page__button {
  width: 200px;
  font-size: 18px;
}
